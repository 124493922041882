import React from "react";
import PresentationCard from "../components/PresentationCard";
import { values } from "../data";

const AboutUs = () => {
  return (
    <div id="about-us" className="about-us-container">
      <div className="section-title">Quem somos</div>

      <p className="about-text">
        A Pé de Maple Education é uma agência educacional e de planejamento de
        mudança para o Canadá. Contamos com mais de 03 anos de experiência na
        área educacional canadense, em especial programas de inglês e ajudamos
        pessoas a escolherem a escola ideal de acordo com a necessidade de cada
        um.
      </p>

      <div>
        <h3 className="section-subtitle">Como surgiu a Pé de Maple?</h3>
        <p className="about-text">
          Os sócios, Fernando e Rayane, tiveram a ideia de abrir uma agência
          educacional assim que chegaram no Canadá, já que eles tiveram a
          experiência de fazer todo o planejamento por conta própria diante da
          dificuldade de encontrar informações sobre as províncias do Atlântico
          canadense, região em que decidiram morar. Após mais de 03 anos de
          experiência trabalhando direta e indiretamente na área, decidiram
          abrir um espaço para ajudar novas pessoas e famílias que assim como
          eles também desejam se mudar para o Canadá.
        </p>
      </div>

      <div>
        <h3 className="section-subtitle">Nosso time</h3>
        <PresentationCard />
      </div>

      <div className="d-flex flex-direction-row mb-5 flex-wrap justify-content-center value-cards">
        {values.map(({ label, description }) => (
          <div key={label} className="value-card">
            <h2>{label}</h2>
            <p>{description}</p>
          </div>
        ))}
      </div>

      <p className="about-text">
        A Pé de Maple Education pode ajudá-lo na escolha do curso e da matrícula
        na instituição do seu interesse no Canadá. Além de passar informações
        valiosas sobre o planejamento para viver no Canadá. Representamos uma
        grande quantidade de escolas de idiomas, colleges e universidades em
        várias províncias canadenses.
      </p>
    </div>
  );
};

export default AboutUs;
