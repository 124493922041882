import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import HeaderLogo from '../assets/logo_principal.png'
import Halifax1 from '../assets/halifax1.jpg'
import Vanvouver1 from '../assets/vancouver1.png'
import Toronto1 from '../assets/toronto1.jpg'
import Ottawa1 from '../assets/ottawa1.jpg'

import '../style/home.scss'


const HeaderCarousel = () => {
  return (
     <div className="header-carousel" >
        <img className="header-image" src={HeaderLogo} alt="" />
        <Carousel controls={false} fade >
            <Carousel.Item>
                <img
                className="d-block w-100
                 carousel-image"
                src={Halifax1}
                alt="First slide"
                />
            </Carousel.Item>
            <Carousel.Item>
                <img
                className="d-block w-100
                 carousel-image"
                src={Vanvouver1}
                alt="Second slide"
                />
            </Carousel.Item>
            <Carousel.Item>
                <img
                className="d-block w-100
                 carousel-image"
                src={Toronto1}
                alt="Third slide"
                />
            </Carousel.Item>
            <Carousel.Item>
                <img
                className="d-block w-100
                 carousel-image"
                src={Ottawa1}
                alt="Third slide"
                />
            </Carousel.Item>
        </Carousel>

        
     </div>
  );
}


export default HeaderCarousel