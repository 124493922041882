import React from "react";
import BasePage from "./pages/BasePage";
import Destination from "./pages/Destination";
import AboutUs from "./pages/AboutUs";
import Contact from "./pages/Contact";
import HeaderCarousel from "./components/HeaderCarousel";
// import Testemonials from "./pages/Testemonials";


import "./style/index.scss";

export default function App() {
	return (

		<BasePage>
			<div id="home" >
				<HeaderCarousel />
			</div>
			<div className="body-container">
				<AboutUs />
				<Destination />
				{/* <Testemonials /> */}
				<Contact />
			</div>
		</BasePage>

	);
}
