import { FaInstagram, FaFacebook } from "react-icons/fa";
import React from "react";

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="footer-text w-25">
        <p className="footer-text">© Todos os direitos reservados.</p>
      </div>
      <div>
        <a
          href="https://www.instagram.com/pedemaple/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaInstagram className="footer-icon" />
        </a>
        <a
          href="https://www.facebook.com/pedemapleeducation/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaFacebook className="footer-icon" />
        </a>
      </div>
    </div>
  );
};

export default Footer;
