import React from "react";
import Fernando from "../assets/fernando.jpg";
import Rayane from "../assets/rayane.png";

const PresentationCard = () => {
  return (
    <>
      <div className="blog-card">
        <div className="meta">
          <div
            className="photo"
            style={{ backgroundImage: `url(${Rayane})` }}
          ></div>
        </div>
        <div className="description">
          <h3>Rayane</h3>
          <h4>Consultora educacional</h4>
          <p>
            Rayane é a nossa consultora educacional, morou quase 6 meses nos
            Estados Unidos. Formada em Psicologia no Brasil, com pós- graduação
            em Recursos Humanos pela Nova Scotia Community College. Tem
            experiência trabalhando com imigrantes desde que chegou no Canadá em
            2019. Para iniciar o curso de Recursos Humanos no Canadá, Rayane
            precisou estudar um curso de inglês acadêmico, mais conhecido como
            Pathway.
          </p>
        </div>
      </div>

      <div className="blog-card alt">
        <div className="meta">
          <div
            className="photo"
            style={{ backgroundImage: `url(${Fernando})` }}
          ></div>
        </div>
        <div className="description">
          <h3>Fernando</h3>
          <h4>Consultor educacional</h4>
          <p>
            Fernando é o nosso consultor educacional. Ele possui 3 anos de
            experiência como recrutador educacional no Canadá, tendo mais de 8
            anos de experiência na área educacional. <br></br>
            Ele mora em Halifax desde 2019, e quando veio para o Canadá também
            estudou em uma escola de inglês para melhorar o aprendizado do
            idioma.
          </p>
        </div>
      </div>
    </>
  );
};

export default PresentationCard;
