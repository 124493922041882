import React from "react";
import ReactDOM from "react-dom/client";
import { Route, Routes } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import AboutUs from "./pages/AboutUs";
import Budget from "./pages/Contact";
import Destination from "./pages/Destination";
import "./i18next.jsx";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<BrowserRouter>
		<Routes>
			<Route path="/" element={<App />} />
			<Route path="/about-us" element={<AboutUs />} />
			<Route path="/destination" element={<Destination />} />
			<Route path="/budget" element={<Budget />} />
		</Routes>
	</BrowserRouter>,
);
