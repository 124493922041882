import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useForm } from "@formspree/react";
import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import Folha from "../assets/folha.png";

const Contact = () => {
  const [state, handleSubmit] = useForm("xyyaybkw");

  const popoverSuccess = (
    <Popover id="popover-basic">
      <Popover.Body>
        <strong>Obrigado!</strong> Em breve entraremos em contato.
      </Popover.Body>
    </Popover>
  );

  const popoverError = (
    <Popover id="popover-basic">
      <Popover.Body>Preencha os campos corretamente.</Popover.Body>
    </Popover>
  );
  const popoverSubmitting = (
    <Popover id="popover-basic">
      <Popover.Body>Enviando...</Popover.Body>
    </Popover>
  );

  return (
    <div id="contact" className="contact-container">
      <div className="section-title">Entre em contato</div>
      <p>
        Está esperando o que para entrar em contato conosco? Basta preencher o
        formulário abaixo que iremos avaliar seu perfil e te contactar o mais
        rápido possível.
      </p>
      <img className="bg-leaf" src={Folha} alt="" />
      <div className="contact-container d-flex ">
        <Form
          onSubmit={handleSubmit}
          id="fs-frm"
          name="survey-form"
          acceptCharset="utf-8"
          action="https://formspree.io/f/mzbwnllp"
          method="post"
          className="w-100"
        >
          <Form.Group className="mb-3">
            <Form.Label>Nome completo</Form.Label>
            <Form.Control
              type="text"
              required
              name="name"
              id="full-name"
              feedback="Preencha esse campo para prosseguir"
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              feedback="Preencha esse campo para prosseguir"
              type="email"
              name="_replyto"
              id="email-address"
              required
              placeholder="name@example.com"
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Telefone (Whatsapp)</Form.Label>
            <Form.Control
              type="tel"
              name="name"
              id="telefone"
              placeholder="(DDD) 99999-9999"
              required
            />
          </Form.Group>

          <Form.Group className="mb-3 d-flex align-center flex-wrap">
            <Form.Label htmlFor="program">Tipo de Programa</Form.Label>
            <Form.Select
              aria-label="Default select example"
              name="program"
              id="program"
              className="ddl-program"
            >
              <option>Escolha uma opçao</option>
              <option value="Ingles geral">Inglês Geral</option>
              <option value="Inglês Universitário (Pathway)">
                Inglês Universitário (Pathway)
              </option>
              <option value="College">College</option>
              <option value="Universidade">Universidade</option>
              <option value="Outros">Outros</option>
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label htmlFor="inicio">
              Quando pretende começar os estudos?
            </Form.Label>
            <Form.Control
              feedback="Preencha esse campo para prosseguir"
              type="text"
              name="name"
              id="inicio"
              placeholder="Mês e ano"
              required
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label htmlFor="message">Me conte seus planos!</Form.Label>
            <Form.Control as="textarea" name="message" id="message" rows={3} />
          </Form.Group>

          <Form.Check
            required
            value="yes"
            feedback="Preencha esse campo para prosseguir"
            feedbackType="invalid"
            name="Autorizo a Pé de Maple Education a entrar em contato, tanto via e-mail, quanto via telefone"
            label=" * Autorizo a Pé de Maple Education a entrar em contato, tanto via e-mail, quanto via telefone."
          />

          <OverlayTrigger
            trigger="click"
            placement="bottom"
            overlay={
              state.succeeded
                ? popoverSuccess
                : state.submitting
                ? popoverSubmitting
                : popoverError
            }
            rootClose
          >
            <Button
              type="submit"
              className="contact-btn"
              disabled={state.submitting}
            >
              Enviar
            </Button>
          </OverlayTrigger>
        </Form>
      </div>
    </div>
  );
};

export default Contact;
