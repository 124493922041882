import { useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useTranslation } from "react-i18next";
import { Link } from "react-scroll";
import Logo from "../../assets/logo_principal.png";
import { FaBars, FaTimes } from "react-icons/fa";

const CollapsibleNavbar = () => {
  const { i18n } = useTranslation();
  const enableTranslations = false;
  const [expanded, setExpanded] = useState(false);

  const routes = [
    { path: "home", label: "Home" },
    { path: "about-us", label: "Quem Somos" },
    { path: "destination", label: "Destinos" },
    // { path: "testemonials", label: "Depoimentos" },
    { path: "contact", label: "Contato" },
  ];

  const handleLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <Navbar expanded={expanded} expand="lg" className="navbar" sticky="top">
      <Container>
        <Navbar.Brand href="/">
          <img className="logo" src={Logo} alt="Logo" />
        </Navbar.Brand>
        <Navbar.Toggle
          onClick={() => setExpanded(expanded ? false : "expanded")}
          aria-controls="responsive-navbar-nav"
        >
          {expanded ? <FaTimes /> : <FaBars />}
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            {routes.map(({ path, label }) => (
              <Link
                activeClass="active"
                className="nav-item"
                duration={1000}
                key={label}
                offset={-70}
                smooth={true}
                spy={false}
                to={path}
                onClick={() => setExpanded(false)}
              >
                {label}
              </Link>
            ))}

            {enableTranslations && (
              <div>
                <button onClick={() => handleLanguage("en-US")}>EN</button>
                <button onClick={() => handleLanguage("pt-BR")}>PT</button>
              </div>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default CollapsibleNavbar;
