import Halifax2 from "./assets/halifax2.jpg";
import Ottawa2 from "./assets/ottawa2.jpg";
import Toronto2 from "./assets/toronto2.jpg";
import Vancouver2 from "./assets/vancouver2.jpg";
import Avatar from './assets/avatar.jpeg';

export const values = [
    {
        label: "Missão",
        description:
            "Proporcionar a melhor experiência possível para quem deseja estudar no Canadá,  acompanhando de perto cada um dos nossos clientes e ajudando com informações detalhadas sobres as instituições de ensino canadense.",
    },
    {
        label: "Visão",
        description:
            "Ser referência em programas de educação canadense para alunos brasileiros.",
    },
    {
        label: "Valores",
        description:
            "Satisfação do cliente e excelência no atendimento. Honestidade, comprometimento e transparência nas relações com nossos clientes. Encorajar clientes a conquistar seus objetivos e desafios",
    },
];

export const destinations = [
    {
        id: 1,
        name: "Halifax",
        src: Halifax2,
        description:
            "Em Halifax você irá encontrar muitos parques, trilhas e praias, o que faz dela uma ótima cidade para quem ama natureza. É também uma opção se você deseja viver em uma cidade com a sensação de uma cidade menor, mas que também oferece tudo que você precisa. Estude numa das cidades com clima de inverno mais ameno do Canadá, que conta com 10 universidades e faculdades atraindo estudantes do mundo inteiro. E cerca de 97% da população que fala inglês. ",
        show: false,
    },
    {
        id: 2,
        name: "Toronto",
        src: Toronto2,
        description:
            "Estude inglês nesta artística, histórica e simpática cidade canadense. Toronto é uma mistura de culturas de todo o mundo. Com centro movimentado de restaurantes e pontos de referência renomados - incluindo a mundialmente famosa CN Tower - a cidade também possui também uma grande quantidade de espaços verdes como o High Park. Toronto abriga muitas das principais atrações artísticas vindas para o Canadá.",
        show: false,
    },
    {
        id: 3,
        name: "Vancouver",
        src: Vancouver2,
        description:
            "Vancouver é uma cidade à beira-mar cercada por montanhas cobertas de neve, florestas exuberantes e praias. É conhecida como a “Hollywood North” por sua indústria cinematográfica e televisiva. As pessoas nesta cidade da Costa Oeste desfrutam de acesso às praias, restaurantes famosos, trilhas nas montanhas, esqui ou snowboard e uma vibrante cultura urbana em uma paisagem natural inigualável.",
        show: false,
    },
    {
        id: 4,
        name: "Ottawa",
        src: Ottawa2,
        description:
            "Ottawa é uma cidade de parques e rios, de extensas ciclovias, de museus, galerias de arte e universidades. Mesmo se modernizando, a cidade manteve o seu charme de cidade pequena. Apesar de seu papel como capital do Canadá, Ottawa permaneceu menor que cidades como Toronto, Montreal e Vancouver. Ela ocupa o quarto maior lugar entre as áreas metropolitanas canadenses.",
        show: false,
    },
];

export const testemonials = [
    {
        avatar: Avatar,
        name: "Aline Medeiros",
        cclassNameade: "Recife",
        depoimento: "Steven and Hazel Marketing are absolute pros when it comes to driving online traffic to businesses. If your website is lacking the online visibility that it deserves, I highly suggest getting in touch with Steven and let him help you get to the next level."
    },
    {
        avatar: Avatar,
        name: "Mariana Medeiros",
        cclassNameade: "Recife",
        depoimento: "Steven and Hazel Marketing are absolute pros when it comes to driving online traffic to businesses. If your website is lacking the online visibility that it deserves, I highly suggest getting in touch with Steven and let him help you get to the next level."
    }
]