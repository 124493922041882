import React from "react";
import Footer from "../components/base/Footer";
import Navbar from "../components/base/Navbar";

const BasePage = ({ children }) => {
  return (
    <>
      <Navbar />
      {children}
      <Footer />
    </>
  );
};

export default BasePage;
