import Card from "react-bootstrap/Card";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import React, { useState } from "react";
import useMountTransition from "../helper";
import "../style/destination.scss";
import { destinations } from "../data";

const Destination = () => {
  const [cards, setCards] = useState(destinations);
  const hasTransitionedIn = useMountTransition(cards, 500);

  const toggleDescription = (id) => {
    setCards((prevCards) =>
      prevCards.map((card) =>
        card.id === id ? { ...card, show: !card.show } : card
      )
    );
  };

  return (
    <div id="destination">
      <div className="section-title">Destinos</div>
      <div className="container d-flex flex-wrap justify-content-center">
        {cards.map(({ id, name, description, src, show }) => (
          <Card key={id}>
            <Card.Img
              className={show ? "no-border" : "border"}
              variant="top"
              src={src}
              alt=""
            />

            <h5 className="destination-name">{name}</h5>
            <div
              className="destination-button"
              onClick={() => {
                toggleDescription(id);
              }}
            >
              {show && <FaAngleUp />}
              {!show && <FaAngleDown />}
            </div>

            {(hasTransitionedIn || show) && (
              <Card.Body
                className={`text ${hasTransitionedIn && "in"} ${
                  show && "visible"
                }`}
              >
                <Card.Text>{description}</Card.Text>
              </Card.Body>
            )}
          </Card>
        ))}
      </div>
      <p className="container text-center">
        Tem interesse em outros destinos canadenses? Conta aqui para a gente que
        podemos te ajudar
      </p>
    </div>
  );
};

export default Destination;
